<template>
   <div class="chart" :style="{height:'300px',width:'100%'}" />
</template>

<script>
import * as echarts from 'echarts'
import { request } from '@/assets/js/http.js'
require('echarts/theme/macarons')
export default {
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  methods: {
    initChart () {
      this.chart = echarts.init(this.$el, 'macarons')
      request('/api/supplier/basicData/getSupplierEchars', 'get').then((res) => {
        if (res.code === '200') {
          this.chart.setOption({
            title: {
              text: res.data.total,
              left: 'center',
              top: 9,
              textStyle: { // 标题样式
                color: '#666',
                fontSize: 16,
                fontWeight: 400,
                fontFamily: 'Microsoft YaHei'
              }
            },
            tooltip: {
              trigger: 'item',
              backgroundColor: 'rgb(31, 35, 41)',
              textStyle: { color: 'rgb(255, 255, 255)' },
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              left: 'center',
              bottom: 0,
              data: res.data.title
            },
            series: {
              name: '数量',
              type: 'pie',
              size: '160%',
              labelLine: {
                length: 6
              },
              // roseType: 'radius',
              radius: '50%', // 饼图的大小
              // center: ['50%', '48%'],
              data: res.data.list,
              itemStyle: {
                color: function (params) {
                  var colorList = [
                    'rgb(48, 189, 130)', 'rgb(68, 135, 250)', 'rgb(255, 101, 66)'
                  ]
                  return colorList[params.dataIndex]
                }
              }
            }
          })
        }
      })
    }
  }
}
</script>
